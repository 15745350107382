import React from "react"
import Helmet from "react-helmet"

export default props => {
  const groupUids = props.groupUids || []

  const defaultVal = props.default || false

  // make sure there's a "enabled" state for each group
  const enabled = groupUids.reduce((c, gUid) => {
    if (c[gUid] !== undefined) return c
    c[gUid] = defaultVal
    return c
  }, props.enabled || {})

  // the groupUid "*" is loaded first and always enabled

  const functions = (props.functions || [])
    .map(f => {
      f["enabled"] = f.groupUid === "*" ? true : enabled[f.groupUid]
      return f
    })
    .sort((a, b) => {
      if (a.groupUid === "*") return -1
      if (a.groupUid === b.groupUid) return 0
      return 1
    })

  return (
    <>
      {functions.map((func, i) => {
        switch (func.kind) {
          case "GTagManager":
            return (
              <div key={i}>
                <Helmet>
                  <script>{`window.dataLayer=window.dataLayer||[];function gtag(){dataLayer.push(arguments)};gtag('consent','default',{'ad_storage':'denied','analytics_storage':'denied'})`}</script>
                  <script>{`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','${func.params}')`}</script>
                </Helmet>

                <noscript>
                  <iframe
                    src={
                      "https://www.googletagmanager.com/ns.html?id=" +
                      func.params
                    }
                    height="0"
                    width="0"
                    title="gtm"
                    style={{ display: "none", visibility: "hidden" }}
                  />
                </noscript>
              </div>
            )
          case "GTagManagerAnalyticsConsent":
            if (!func.enabled) return null
            return (
              <Helmet key={i}>
                <script>{`gtag('consent','update',{'analytics_storage':'granted'})`}</script>
                {func.params && (
                  <script>{`dataLayer.push({'event':'${func.params}'})`}</script>
                )}
              </Helmet>
            )
          case "GTagManagerAdsConsent":
            if (!func.enabled) return null
            return (
              <Helmet key={i}>
                <script>{`gtag('consent','update',{'ad_storage':'granted'})`}</script>
                {func.params && (
                  <script>{`dataLayer.push({'event':'${func.params}'})`}</script>
                )}
              </Helmet>
            )
          case "GTagManagerMarketingConsent":
            if (!func.enabled) return null
            return (
              <Helmet key={i}>
                <script>{`dataLayer.push({'event':'${
                  func.params || "cc_mrk_cons"
                }'})`}</script>
              </Helmet>
            )
          default:
            return null
        }
      })}
    </>
  )
}
