import React from "react"

// import Checkbox from "../../../form/control/checkbox"

import styles from './component.module.css'

export default class extends React.Component {

  groups;

  constructor(props) {
    super(props);

    this.groups = props.groups || [];

    this.state = this.groups.reduce((c, group) => {
      if (c[group.uid] !== undefined) return c;
      c[group.uid] = props.default || false;
      return c;
    }, (props.values || {}));
  }

  render() {
    return (
        <ol className={styles.groups}>
          {this.groups.map((group, i) => (
              <li key={i}>
                <input
                    type="checkbox"
                    id={'ggcc-g' + i}
                    name={'ggcc-g' + i}
                    checked={this.state[group.uid]}
                    onChange={e => {
                      const newState = this.state;
                      newState[group.uid] = e.target.checked;
                      this.setState(newState);
                    }}
                /><span className={styles.name}>{group.name}</span>

                {group.description &&
                    <div className={styles.desc} dangerouslySetInnerHTML={{__html: group.description}} />
                }
              </li>
          ))}
        </ol>
    );
  }
}
